(function() {
  $(function() {
    var $dropdown_subtopic, $dropdown_topic, sub, top;
    $dropdown_topic = $('#matter_topic, #search_form_client_topic');
    $dropdown_subtopic = $('#matter_subtopic, #search_form_client_subtopic');
    if ($dropdown_topic.length) {
      top = window.matter_topic_selected;
      sub = window.matter_subtopic_selected;
      return $.ajax({
        url: "/matters/topics.json"
      }).done(function(json) {
        var populate_subtopics, topic;
        // populate topics
        $dropdown_topic.html($('<option></option>'));
        for (topic in json) {
          $dropdown_topic.append($('<option></option>').val(topic).text(topic.replace(/--/g, '/')));
        }
        $("option[value='" + top + "']", $dropdown_topic).attr('selected', 'selected');
        populate_subtopics = function() {
          var $topic, i, len, ref, results, subtopic;
          $topic = $("option:selected", $dropdown_topic).text().replace(/\//g, '--'); // reverse
          $dropdown_subtopic.html($('<option></option>'));
          ref = json[$topic];
          results = [];
          for (i = 0, len = ref.length; i < len; i++) {
            subtopic = ref[i];
            results.push($dropdown_subtopic.append($('<option></option>').val(subtopic).text(subtopic.replace(/--/g, '/'))));
          }
          return results;
        };
        $dropdown_topic.change(function() {
          return populate_subtopics();
        });
        if (top) {
          populate_subtopics();
          return $("option[value='" + sub + "']", $dropdown_subtopic).attr('selected', 'selected');
        }
      });
    }
  });

}).call(this);
