(function() {
  // Place all the behaviors and hooks related to the matching controller here.
  // All this logic will automatically be available in application.js.
  // You can use CoffeeScript in this file: http://coffeescript.org/
  $(function() {
    $('#client_country_of_origin').change(function() {
      return $(this).closest('form').find('.client_country_of_origin_other').toggle($(this).val() === 'Andet');
    });
    return $('#client_country_of_origin').trigger('change');
  });

}).call(this);
