(function() {
  $(function() {
    return $('.datepicker').datepicker({
      format: 'dd-mm-yyyy',
      language: 'da',
      calendarWeeks: true,
      autoclose: true,
      todayHighlight: true,
      pickTime: true,
      weekStart: 1
    });
  });

}).call(this);
