import "./jquery_loader";
import Popper from  'popper.js';
import rails from   "@rails/ujs"; 

import 'bootstrap';

import 'bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.da.js';

import './datepicker.js.coffee';
import './clients.js.coffee';
import './topics.js.coffee';

jQuery(document).ready( () => {
  rails.start();
  return true;
})
